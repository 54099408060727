import config from '../../../config';
import { UserInfo } from '../../../stores/models';
import { UserRole } from '../../../types/enums';
import { getDashboardRoutePath } from '../../../utils/routes-util';
import { hasRoles } from '../../../utils/users-util';

export const MAIN_NAV = (
  { defaultLotId = '', roles }: UserInfo,
  profileId?: string,
  isAuthenticated?: boolean
): (
  | {
      hide: boolean | undefined;
      href: string;
      label: string;
      links?: undefined;
    }
  | {
      hide: boolean;
      href: string;
      label: string;
      links: { href: string; label: string }[];
    }
)[] =>
  [
    {
      hide:
        !hasRoles(roles, [
          UserRole.Admin,
          UserRole.Intern,
          UserRole.LotOwner,
        ]) || !isAuthenticated,
      href: config.routes.docs,
      label: 'Help Center',
    },
    {
      hide:
        !hasRoles(roles, [
          UserRole.Admin,
          UserRole.Intern,
          UserRole.LotOwner,
        ]) || !isAuthenticated,
      href: getDashboardRoutePath(
        config.routes.dashboard.events.home,
        profileId || defaultLotId
      ),
      label: 'Dashboard',
    },
    {
      hide: !hasRoles(roles, [
        UserRole.Admin,
        UserRole.Intern,
        UserRole.SuperAdmin,
      ]),
      href: 'https://admins.goodfynd.com',
      label: 'Admins Dashboard',
    },
    {
      hide: !isAuthenticated,
      href: config.routes.dashboard.add,
      label: 'Create an Event',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signIn,
      label: 'Log in',
    },
    {
      hide: isAuthenticated,
      href: config.routes.account.signUp,
      label: 'Sign up',
    },
  ].filter((item) => !item.hide);
