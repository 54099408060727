import Head from 'next/head';
import React, { useCallback } from 'react';

import { Footer } from '@goodfynd/react-web.layout.footer';
import { PromoCta } from '@goodfynd/react-web.lib.content';

import CmsHeader from '../components/cms/CmsHeader';
import LayoutFull from '../components/layout/LayoutFull';
import config from '../config';
import { useCmsPage } from '../hooks';
import { scrollToTop, setPageTitle } from '../utils/base';
import {
  renderCmsBodyComponent,
  renderCmsTitleComponent,
} from '../utils/cms-util';

import type { CmsHeaderProps } from '../components/cms/CmsHeader/types';
import type { PromoCtaProps } from '@goodfynd/react-web.lib.content';
import type { FooterProps } from '@goodfynd/react-web.layout.footer';
function Home(): JSX.Element {
  const { body, seo, header, title, footer } = useCmsPage('event-home-page');

  const renderBody = useCallback(
    () =>
      body?.map(({ fields, type }, index) => {
        switch (type) {
          case 'two_column_image_left':
            return (
              <PromoCta
                {...(fields as PromoCtaProps)}
                buttonOnClick={function () {
                  scrollToTop();
                }}
                key={type + index}
              />
            );

          case 'two_column_image_right':
            return (
              <PromoCta
                image_position="right"
                {...(fields as PromoCtaProps)}
                buttonOnClick={function () {
                  scrollToTop();
                }}
                key={type + index}
              />
            );

          default:
            return renderCmsBodyComponent(index, type, fields);
        }
      }),
    [body]
  );

  const renderTitle = useCallback(
    () => (
      <>
        {title?.map(({ fields, type }, index) =>
          renderCmsTitleComponent(index, type, fields)
        )}
      </>
    ),

    [title]
  );

  return (
    <>
      {seo && (
        <Head>
          <title>{setPageTitle('Home')}</title>
          <meta name="description" content={seo?.description} />
        </Head>
      )}

      <div style={{ paddingTop: header ? 64 : 0 }}>
        {header && <CmsHeader {...(header as unknown as CmsHeaderProps)} />}

        {renderTitle()}
        <div
          style={{
            marginTop: 40,
          }}
        >
          {renderBody()}
        </div>

        {footer && (
          <Footer
            drift_interaction_id={config.env.DRIFT_INTERACTION_ID}
            {...(footer as unknown as FooterProps)}
          />
        )}
      </div>
    </>
  );
}

Home.getLayout = function getLayout(page: React.ReactElement) {
  return <LayoutFull>{page}</LayoutFull>;
};

export default Home;
